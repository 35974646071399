import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./CustomTechStacks.scss"
import * as styles from "./CustomTechStacks.module.scss"

const data = [
  {
    title: "Languages",
    tech: [
      {
        name: "HTML5",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_2ace12d302.svg",
      },
      {
        name: "CSS3",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_4bb617a30d.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_6815655da5.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_2f934421ed.svg",
      },
      {
        name: "React",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_687d85de86.svg",
      },
      {
        name: "Meteor",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteor_239e798816.svg",
      },
      {
        name: "Vue.JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_4dcead6d7d.svg",
      },
      {
        name: "Next.JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_e1adbab048.svg",
      },
      {
        name: "Ember",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ember_js_b32f5fffba.svg",
      },
      {
        name: "Node.JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_b925a34d58.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_ad9e3730d7.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_6bf031a8cd.svg",
      },
      {
        name: "Microsoft.Net",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_dot_net_1a9d79c56d.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_e91435e6d1.svg",
      },
      {
        name: "Go",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_0c41eb4551.svg",
      },
    ],
  },
  {
    title: "Databases",
    tech: [
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_422f7db8a9.svg",
      },
      {
        name: "Oracle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_1e51a29f90.svg",
      },
      {
        name: "Apache HBase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/apache_h_base_605eda8d7f.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_1b8b78d0c5.svg",
      },
      {
        name: "Azure SQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_sql_32d7f24c93.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_e8443cf676.svg",
      },
    ],
  },
  {
    title: "Storage (Cloud Services)",
    tech: [
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_416ddf0f50.svg",
      },
      {
        name: "Microsoft Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_azure_8286521de9.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_5079140ead.svg",
      },
    ],
  },
]

const CustomTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="customTabStacks">
      <div className={styles.customTechStacksWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContent}>
              <div>
                <Nav variant="pills" className={`customTabStacks`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockcustom">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default CustomTechStacks
