// extracted by mini-css-extract-plugin
export var cardBg = "CustomTechStacks-module--cardBg--4af2f";
export var cards = "CustomTechStacks-module--cards--f997d";
export var cardsContent = "CustomTechStacks-module--cardsContent--d38be";
export var customTechStacksWeb = "CustomTechStacks-module--customTechStacksWeb--9d41c";
export var description = "CustomTechStacks-module--description--51663";
export var heading = "CustomTechStacks-module--heading--fd8a1";
export var iconssCard = "CustomTechStacks-module--iconssCard--a91c0";
export var nav = "CustomTechStacks-module--nav--81f19";
export var navItem = "CustomTechStacks-module--nav-item--6f4cd";
export var navbarBlock = "CustomTechStacks-module--navbarBlock--ddff2";
export var tabData = "CustomTechStacks-module--tabData--20691";
export var tech = "CustomTechStacks-module--tech--58626";
export var techIcon = "CustomTechStacks-module--techIcon--217e7";
export var techImg = "CustomTechStacks-module--techImg--b2284";
export var technologyIcon = "CustomTechStacks-module--technologyIcon--8d5f7";