import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import laiba from "../../images/hire-web-developers/laiba-sajid.svg"
import abdul from "../../images/hire-web-developers/abdul-karim.svg"
import laeeq from "../../images/hire-web-developers/laeeq-a.svg"
import stars4 from "../../images/hire-typescript/4.5Stars.svg"
import fiveStars from "../../images/hire-typescript/5Stars.svg"
import * as styles from "./HireDevelopers.module.scss"
import plus from "../../images/hire-typescript/btnPlus.svg"
import mernIcon from "../../images/hireMernStack/armyIcon.svg"
const HireDevelopers = ({ strapiData }) => {
  const [showMoreSkills, setShowMoreSkills] = useState(false)
  const [showMoreSkills1, setShowMoreSkills1] = useState(false)
  const [showMoreSkills2, setShowMoreSkills2] = useState(false)
  const [showLessSkills, setShowLessSkills] = useState(false)
  const [showLessSkills1, setShowLessSkills1] = useState(false)
  const [showLessSkills2, setShowLessSkills2] = useState(false)

  const toggleShowMoreSkills = () => {
    setShowMoreSkills(!showMoreSkills)
    setShowLessSkills(false)
  }
  const toggleShowMoreSkills1 = () => {
    setShowMoreSkills1(!showMoreSkills1)
  }
  const toggleShowMoreSkills2 = () => {
    setShowMoreSkills2(!showMoreSkills2)
  }
  const toggleShowLessSkills = () => {
    setShowMoreSkills(false)
    setShowLessSkills(!showLessSkills)
  }
  const toggleShowLessSkills1 = () => {
    setShowMoreSkills1(false)
    setShowLessSkills1(!showLessSkills1)
  }
  const toggleShowLessSkills2 = () => {
    setShowMoreSkills2(false)
    setShowLessSkills2(!showLessSkills2)
  }
  const devSDetails = [
    {
      name: "Abdul Karim",
      experience: "8 years of experience",
      desc: "Abdul Karim is a coding genius, rocking both front-end and back-end development. His love for tidy code shines through, guaranteeing solid functionalities & smooth experiences.",
      rating: fiveStars,
      reviews: "<b>5.0</b> Reviews",
    },
    {
      name: "Laiba Sajid",
      experience: "5 years of experience",
      desc: "Laiba is a seasoned full-stack web developer with extensive experience in creating software solutions. Her background includes building robust solutions using agile methods.",
      rating: stars4,
      reviews: "<b>5.0</b> Stars Reviews",
    },
    {
      name: "Laeeq A",
      experience: "4+ years of experience",
      desc: "With broad experience in web and mobile application development, Laeeq has actively contributed to projects incorporating Deep Learning and Computer Vision technologies.",
      rating: stars4,
      reviews: "<b>4.9</b> Stars Reviews",
    },
  ]
  const dataDev1 = [
    {
      skill: "CSS",
    },
    {
      skill: "SQL",
    },
    {
      skill: "HTML5",
    },
    {
      skill: "MySQL",
    },
    {
      skill: "REST APIs",
    },
    {
      skill: "Node.js",
    },
    {
      skill: "React.js",
    },
    {
      skill: "Agile",
    },
    {
      skill: "UX",
    },
    {
      skill: "UI",
    },
  ]
  const dataDev2 = [
    {
      skill: "HTML",
    },
    {
      skill: "JavaScript",
    },
    {
      skill: "CSS",
    },
    {
      skill: "jQuery",
    },
    {
      skill: "React",
    },
    {
      skill: "MS SQLServer",
    },

    {
      skill: "PHP",
    },
    {
      skill: "Redis",
    },
    {
      skill: "Microservices",
    },
  ]
  const dataDev3 = [
    {
      skill: "Angular",
    },
    {
      skill: "CSS",
    },
    {
      skill: "Python",
    },
    {
      skill: "Java",
    },
    {
      skill: "JavaScript",
    },
    {
      skill: "PHP",
    },
    {
      skill: "jQuery",
    },
    {
      skill: "WordPress",
    },
    {
      skill: "Express.JS",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.AngularDevelopersWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {devSDetails &&
              devSDetails.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={` d-flex ${styles.developersData}`}>
                      <div className={styles.developerImg}>
                        <img
                          src={
                            i === 0
                              ? abdul
                              : i === 1
                              ? laiba
                              : i === 2
                              ? laeeq
                              : ""
                          }
                          alt={e?.name}
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.devContent}>
                        <h3>{e?.name}</h3>
                        <p className={styles.experience}>{e?.experience}</p>
                      </div>
                    </div>
                    <div className={styles.allSkills}>
                      {i === 0 && (
                        <React.Fragment>
                          {dataDev1 &&
                            dataDev1
                              .slice(0, showMoreSkills ? dataDev1.length : 6)
                              .map((s, eIdx) => (
                                <div className={styles.skills} key={eIdx}>
                                  <div
                                    className={`${styles.skill}`}
                                    data-index={eIdx}
                                  >
                                    {s?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {i === 1 && (
                        <React.Fragment>
                          {dataDev2 &&
                            dataDev2
                              .slice(0, showMoreSkills1 ? dataDev2.length : 6)
                              .map((sl, el) => (
                                <div className={styles.skills} key={el}>
                                  <div
                                    className={`${styles.skill}`}
                                    data-index={el}
                                  >
                                    {sl?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills1 && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills1}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills1 && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills1}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {i === 2 && (
                        <React.Fragment>
                          {dataDev3 &&
                            dataDev3
                              .slice(0, showMoreSkills2 ? dataDev3.length : 5)
                              .map((al, ei) => (
                                <div className={styles.skills} key={ei}>
                                  <div
                                    className={`${styles.skill}`}
                                    data-index={ei}
                                  >
                                    {al?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills2 && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills2}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills2 && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills2}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}{" "}
                        </React.Fragment>
                      )}
                    </div>
                    <p className={styles.cardDesc}>{e?.desc}</p>
                    <div className={styles.typedevBtn}>
                      <Link to="#hirewebform">
                        <Button className={styles.typeBtn}>
                          <img
                            src={mernIcon}
                            alt="icon"
                            decoding="async"
                            loading="lazy"
                            className={styles.mernIcon}
                          />
                          {`Hire ${e?.name}`}
                        </Button>
                      </Link>
                    </div>
                    <div className={styles.bottomRating}>
                      <img
                        src={e?.rating}
                        alt="raiting"
                        decoding="async"
                        loading="lazy"
                      />
                      <div
                        className={styles.review}
                        dangerouslySetInnerHTML={{
                          __html: e?.reviews,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default HireDevelopers
