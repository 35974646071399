// extracted by mini-css-extract-plugin
export var bottomContent = "ProjectsMobile-module--bottomContent--1a1f5";
export var bottomHead = "ProjectsMobile-module--bottomHead--0bc60";
export var bottomPort = "ProjectsMobile-module--bottomPort--b18d6";
export var bottomPort2 = "ProjectsMobile-module--bottomPort2--5ccd2";
export var bottomTags = "ProjectsMobile-module--bottomTags--1a373";
export var bottomhead1 = "ProjectsMobile-module--bottomhead1--46afb";
export var bottomtag1 = "ProjectsMobile-module--bottomtag1--e26a1";
export var bottomtag2 = "ProjectsMobile-module--bottomtag2--01b78";
export var bottomtag3 = "ProjectsMobile-module--bottomtag3--d35a7";
export var bottomtag3Hover = "ProjectsMobile-module--bottomtag3Hover--65bee";
export var cardWrapper = "ProjectsMobile-module--cardWrapper--370af";
export var caseStudiesBtnPortfolio = "ProjectsMobile-module--caseStudiesBtnPortfolio--fd5e5";
export var description = "ProjectsMobile-module--description--efabc";
export var firstMobileImagaPort = "ProjectsMobile-module--firstMobileImagaPort--8987c";
export var firstPortBottom = "ProjectsMobile-module--firstPortBottom--88f28";
export var firstPortFolio = "ProjectsMobile-module--firstPortFolio--08443";
export var head = "ProjectsMobile-module--head--f05cf";
export var heading = "ProjectsMobile-module--heading--9a429";
export var hoverFirstPortImg = "ProjectsMobile-module--hoverFirstPortImg--4e01b";
export var normalrFirstPortImg = "ProjectsMobile-module--normalrFirstPortImg--7e4bc";
export var portImage = "ProjectsMobile-module--portImage--14965";
export var portfolioLinkImage = "ProjectsMobile-module--portfolioLinkImage--46c06";
export var project2 = "ProjectsMobile-module--project2--c5309";
export var projectsAll = "ProjectsMobile-module--projectsAll--92f59";
export var projectsMobileWeb = "ProjectsMobile-module--projectsMobileWeb--95d67";
export var tag1 = "ProjectsMobile-module--tag1--f8f93";
export var tag2 = "ProjectsMobile-module--tag2--08bd8";
export var tag3 = "ProjectsMobile-module--tag3--3f9a4";
export var tags = "ProjectsMobile-module--tags--4db78";