// extracted by mini-css-extract-plugin
export var bestDevelopers = "BestDevelopers-module--bestDevelopers--bd174";
export var cards = "BestDevelopers-module--cards--8046f";
export var description = "BestDevelopers-module--description--a049a";
export var hire = "BestDevelopers-module--hire--3250d";
export var hireDesktopWebDevelopers = "BestDevelopers-module--hireDesktopWebDevelopers--5f3d1";
export var hireImg = "BestDevelopers-module--hireImg--6fb83";
export var hireMobileWebDevelopers = "BestDevelopers-module--hireMobileWebDevelopers--a99b9";
export var subTitle = "BestDevelopers-module--subTitle--d8d27";
export var talent = "BestDevelopers-module--talent--93772";
export var text = "BestDevelopers-module--text--56b10";
export var tick = "BestDevelopers-module--tick--09108";