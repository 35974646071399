// extracted by mini-css-extract-plugin
export var SliderWidth = "Projects-module--SliderWidth--bc01f";
export var arrowImg = "Projects-module--arrowImg--fed2c";
export var bottomContent = "Projects-module--bottomContent--89ca7";
export var bottomHead = "Projects-module--bottomHead--5f3d2";
export var bottomPort = "Projects-module--bottomPort--dcd23";
export var bottomPort2 = "Projects-module--bottomPort2--21534";
export var bottomTags = "Projects-module--bottomTags--1b0bc";
export var bottomhead1 = "Projects-module--bottomhead1--d10be";
export var bottomtag1 = "Projects-module--bottomtag1--ad189";
export var bottomtag2 = "Projects-module--bottomtag2--20c13";
export var bottomtag3 = "Projects-module--bottomtag3--6abdd";
export var bottomtag3Hover = "Projects-module--bottomtag3Hover--eb5b7";
export var cardWrapper = "Projects-module--cardWrapper--56815";
export var cards = "Projects-module--cards--dfc82";
export var description = "Projects-module--description--a9baa";
export var heading = "Projects-module--heading--e3a71";
export var headingPremium = "Projects-module--headingPremium--4e8fb";
export var mainWorkProjectsWebServices = "Projects-module--mainWorkProjectsWebServices--1783c";
export var portfolioArrowIcon = "Projects-module--portfolioArrowIcon--7a4d9";
export var portfolioArrowIconCover = "Projects-module--portfolioArrowIconCover--ff143";
export var portfolioArrowRightIconCover = "Projects-module--portfolioArrowRightIconCover--69d8a";
export var portfolioLinkImage = "Projects-module--portfolioLinkImage--e84e5";
export var premiumImg = "Projects-module--premiumImg--1f553";
export var project2 = "Projects-module--project2--a4c35";
export var projectsAll = "Projects-module--projectsAll--f3e30";
export var providingImg = "Projects-module--providingImg--2ad1a";
export var reactJsIconContainer = "Projects-module--reactJsIconContainer--ef5c3";
export var reactJsIconContainerLeft = "Projects-module--reactJsIconContainerLeft--3f825";
export var teamButton = "Projects-module--teamButton--9e5f2";
export var trailBg = "Projects-module--trailBg--8fea0";