// extracted by mini-css-extract-plugin
export var accImg = "Engineering-module--accImg--6bd9a";
export var approachImg = "Engineering-module--approachImg--9d338";
export var btn_white1_border = "Engineering-module--btn_white1_border--d51d8";
export var description = "Engineering-module--description--e5156";
export var engineeringSQA = "Engineering-module--engineeringSQA--32a0b";
export var head = "Engineering-module--head--d8d33";
export var heading = "Engineering-module--heading--1297c";
export var keyBtn = "Engineering-module--keyBtn--2d145";
export var nextGenRight = "Engineering-module--nextGenRight--2a79b";
export var quesColor = "Engineering-module--quesColor--f2e72";
export var quesColorSelected = "Engineering-module--quesColorSelected--79e0a";
export var someColor = "Engineering-module--someColor--eef29";
export var textColor = "Engineering-module--textColor--e920f";