import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import tick from "../../images/hire-saas/tick.svg"
import expert from "../../images/hire-web-developers/skilled-web-developer.png"
import * as styles from "./BestDevelopers.module.scss"

const BestDevelopers = ({ strapiData }) => {
  return (
    <div className={styles.bestDevelopers}>
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hire}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </div>
            <div className={` text-center ${styles.hireMobileWebDevelopers}`}>
              <img
                src={
                  "https://invozone-backend.s3.us-east-1.amazonaws.com/skilled_web_developer_a508216e4e9017d3199b81dfa9fa6f15_60c678216e.png"
                }
                decoding="async"
                loading="lazy"
                alt="Experience the Dynamic Skills of Our Web Developers"
              />
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img
                      className={styles.tick}
                      src={tick}
                      alt={item?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={` text-center ${styles.hireDesktopWebDevelopers}`}>
              <img
                src={
                  "https://invozone-backend.s3.us-east-1.amazonaws.com/skilled_web_developer_a508216e4e9017d3199b81dfa9fa6f15_60c678216e.png"
                }
                decoding="async"
                loading="lazy"
                alt="Experience the Dynamic Skills of Our Web Developers"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BestDevelopers
