// extracted by mini-css-extract-plugin
export var bannerBtnHowAngular = "HowHireAngular-module--bannerBtnHowAngular--a6482";
export var card = "HowHireAngular-module--card--fa0b1";
export var cardAllData = "HowHireAngular-module--cardAllData--9915d";
export var cardContent = "HowHireAngular-module--cardContent--f928d";
export var cardWrapper = "HowHireAngular-module--cardWrapper--cd957";
export var description = "HowHireAngular-module--description--6b6a0";
export var getBtnAngular = "HowHireAngular-module--getBtnAngular--dc9e4";
export var headerContent = "HowHireAngular-module--headerContent--aa23b";
export var heading = "HowHireAngular-module--heading--89d8e";
export var howWebDevelopersHire = "HowHireAngular-module--howWebDevelopersHire--b10f2";
export var lottieImage = "HowHireAngular-module--lottieImage--0460b";
export var steps = "HowHireAngular-module--steps--f376c";