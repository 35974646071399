import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import secondPortfolio from "../../images/portfolioRevamp/portfolio-2.png"
import thirdPortfolio from "../../images/portfolioRevamp/portfolio-3.png"
import fourthPortfolio from "../../images/portfolioRevamp/portfolio-4.png"
import fifthPortfolio from "../../images/portfolioRevamp/portfolio-5.png"
import portfolioLink from "../../images/portfolioRevamp/firstPortfolioLinks.svg"
import * as styles from "./Projects.module.scss"
import "./Projects.scss"

const Projects = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 4 ||
      current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])
  const settings = {
    autoplay: true,
    dots: true,
    dotsClass: "mainWorkProjectsWebServices",
    slidesToShow: 3,
    arrows: false,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    infinite: false,
    pauseOnHover: true,
    speed: 200,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }
  const projects = [
    {
      image: secondPortfolio,
      title: "GlobalReader",
      tag1: "Design",
      tag2: "Development",
      tag3: "AI",
    },
    {
      image: thirdPortfolio,
      title: "Homepie",
      tag1: "Design",
      tag2: "Development",
      tag3: "Real Estate",
    },
    {
      image: fourthPortfolio,
      title: "Shield Republic",
      tag1: "Design",
      tag2: "Development",
      tag3: "Ecommerce",
    },
    {
      image: fifthPortfolio,
      title: "Stitch.Health",
      tag1: "Design",
      tag2: "Development",
      tag3: "Healthcare",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.mainWorkProjectsWebServices}>
        <Container>
          <h2
            className={styles.heading}
            // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            Our Impressive Portfolio Highlights
          </h2>
          <div className={styles.description}>
            Browse through our portfolio, where each project tells a story of
            dedication, skill, and a blend of the creative genius of our mobile
            app developers.
          </div>
          <Row className={` ${styles.cardWrapper}`}>
            <Slider
              {...settings}
              className={`${styles.SliderWidth} mainSliderwebDevelopment `}
            >
              {projects &&
                projects?.map((e, i) => (
                  <Col
                    xs={12}
                    key={i}
                    className={i === 0 ? styles.project2 : styles.projectsAll}
                  >
                    <div>
                      <img
                        src={e?.image}
                        alt={e?.title}
                        decoding="async"
                        loading="lazy"
                      />
                      <div
                        className={
                          i === 1 ? styles.bottomPort2 : styles.bottomPort
                        }
                      >
                        <div className={styles.bottomContent}>
                          <h3
                            className={
                              i === 0
                                ? styles.bottomhead1
                                : i === 1
                                ? styles.bottomhead1
                                : styles.bottomHead
                            }
                          >
                            {e?.title}
                          </h3>
                          <div className={` ${styles.bottomTags}`}>
                            <div className={styles.bottomtag1}>{e?.tag1}</div>
                            <div className={styles.bottomtag2}>{e?.tag2}</div>
                            <div className={styles.bottomtag3}>{e?.tag3}</div>
                          </div>
                        </div>
                        <Link
                          to={
                            i === 0
                              ? "/portfolio/globalreader/"
                              : i === 1
                              ? "/portfolio/homepie/"
                              : i === 2
                              ? "/portfolio/shield-republic/"
                              : i === 3
                              ? "/portfolio/stitch-health/"
                              : ""
                          }
                        >
                          <img
                            src={portfolioLink}
                            className={styles.portfolioLinkImage}
                            alt="portfolio"
                            decoding="async"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>
                  </Col>
                ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Projects
