import { Script, graphql } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-web-developers/Banner"
import Statics from "../components/hire-typescript/Statics"
import Testimonials from "../components/hire-typescript/Testimonials"
import Testimonials2 from "../components/hire-typescript/Testimonials2"
import WhyHireAngular from "../components/hire-web-developers/WhyHireAngular"
import HowHireAngular from "../components/hire-web-developers/HowHireAngular"
import HowHireAngularMobile from "../components/hire-web-developers/HowHireAngularMobile"
import HireDevelopers from "../components/hire-web-developers/HireDevelopers"
import AngularTechStacks from "../components/hire-web-developers/CustomTechStacks"
import AngularTechStacksMobile from "../components/hire-web-developers/CustomTechStacksMobile"
import Engineering from "../components/hire-web-developers/Engineering"
import QuickReads from "../components/hire-laravel-developers/QuickReads"
import Remote from "../components/hire-web-developers/Remote"
import Projects from "../components/hire-web-developers/Projects"
import ProjectsMobile from "../components/hire-web-developers/ProjectsMobile"
import BestDevelopers from "../components/hire-web-developers/BestDevelopers"
import Book from "../components/hire-web-developers/Book"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const typescript = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const angular = data?.strapiPage?.sections[1]
  const developers = data?.strapiPage?.sections[2]
  const how = data?.strapiPage?.sections[3]
  const remote = data?.strapiPage.sections[4]
  const best = data?.strapiPage?.sections[5]
  const TechStacksAngular = data?.strapiPage?.sections[6]
  const tips = data?.strapiPage?.sections[7]
  const book = data?.strapiPage?.sections[8]
  const projects = data?.strapiPage?.sections[9]
  const quick = data?.strapiPage?.sections[12]
  const faqs = data?.strapiPage?.sections[13]
  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Web Developers"
      />
      <Statics />
      <WhyHireAngular strapiData={angular} />
      <HireDevelopers strapiData={developers} />
      {!isMobile && !isTablet ? (
        <HowHireAngular strapiData={how} />
      ) : (
        <HowHireAngularMobile strapiData={how} />
      )}
      <Remote strapiData={remote} />
      <BestDevelopers strapiData={best} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Engineering strapiData={tips} />
      <Book strapiData={book} />
      {!isMobile && !isTablet ? (
        <Projects strapiData={projects} />
      ) : (
        <ProjectsMobile strapiData={projects} />
      )}
      {!isMobile && !isTablet ? (
        <Testimonials
          strapiData={testimonials}
          testiHead={"Our Work Makes Clients Happy"}
          testiSubtitle={
            "Our clients love us! Listen to their stories about how we turned their dreams into real things. Come, and be a part of the success journey we're building together!"
          }
        />
      ) : (
        <Testimonials2
          strapiData={testimonials}
          testiHead={"Our Work Makes Clients Happy"}
          testiSubtitle={
            "Our clients love us! Listen to their stories about how we turned their dreams into real things. Come, and be a part of the success journey we're building together!"
          }
        />
      )}
      <QuickReads strapiData={quick} androidBlog={true} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "hire-web-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default typescript
