// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "WhyHireAngular-module--ServicesWhyAngular--c3b18";
export var WhybannerBtn = "WhyHireAngular-module--WhybannerBtn--8f718";
export var WhycvButtn = "WhyHireAngular-module--WhycvButtn--398bc";
export var card = "WhyHireAngular-module--card--20a37";
export var cardWrapper = "WhyHireAngular-module--cardWrapper--d195f";
export var description = "WhyHireAngular-module--description--ee8b7";
export var heading = "WhyHireAngular-module--heading--9090f";
export var loader = "WhyHireAngular-module--loader--40a0d";
export var lodaMoreBtn = "WhyHireAngular-module--lodaMoreBtn--7dd43";
export var lodalessBtn = "WhyHireAngular-module--lodalessBtn--aee0c";
export var showlessdiv = "WhyHireAngular-module--showlessdiv--8f081";
export var spin = "WhyHireAngular-module--spin--040c0";
export var trailBg = "WhyHireAngular-module--trailBg--20e0f";