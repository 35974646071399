// extracted by mini-css-extract-plugin
export var cardBg = "CustomTechStacksMobile-module--cardBg--dd408";
export var cards = "CustomTechStacksMobile-module--cards--f8d43";
export var cardsContent = "CustomTechStacksMobile-module--cardsContent--75cc6";
export var customTechMobile = "CustomTechStacksMobile-module--customTechMobile--0e58b";
export var description = "CustomTechStacksMobile-module--description--43811";
export var heading = "CustomTechStacksMobile-module--heading--e334e";
export var iconssCard = "CustomTechStacksMobile-module--iconssCard--bf611";
export var nav = "CustomTechStacksMobile-module--nav--06540";
export var navItem = "CustomTechStacksMobile-module--nav-item--3b143";
export var navbarBlock = "CustomTechStacksMobile-module--navbarBlock--30460";
export var tabData = "CustomTechStacksMobile-module--tabData--4a19f";
export var tech = "CustomTechStacksMobile-module--tech--b5ac6";
export var techIcon = "CustomTechStacksMobile-module--techIcon--76507";
export var techImg = "CustomTechStacksMobile-module--techImg--26e1f";
export var technologyIcon = "CustomTechStacksMobile-module--technologyIcon--9c5e7";