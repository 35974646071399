import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Book.module.scss"

const Book = ({ strapiData }) => {
  return (
    <div className={styles.readyBook}>
      <Container className={styles.pad}>
        <div className={styles.readyBg}>
          <Row>
            <Col lg={9} md={12} xs={12} sm={12}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
            <Col lg={3} md={12} xs={12} sm={12} className={styles.ctaBtn}>
              <div className={`${styles.bannerBtn}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.btn_white2_border_SaaS_Book}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Book
