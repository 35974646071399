// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--375fa";
export var bannerSubtitle = "Banner-module--bannerSubtitle--6bccd";
export var bannerSubtitle2 = "Banner-module--bannerSubtitle2--2b6e2";
export var cards = "Banner-module--cards--a459c";
export var form = "Banner-module--form--a6012";
export var heading = "Banner-module--heading--565d3";
export var hireAngularTopBanner = "Banner-module--hireAngularTopBanner--699e6";
export var talent = "Banner-module--talent--7099f";
export var text = "Banner-module--text--8e05c";
export var tick = "Banner-module--tick--149d3";