// extracted by mini-css-extract-plugin
export var AngularDevelopersWeb = "HireDevelopers-module--AngularDevelopersWeb--ab83d";
export var allSkills = "HireDevelopers-module--allSkills--fedf2";
export var bottomRating = "HireDevelopers-module--bottomRating--a89e7";
export var btnShowMore = "HireDevelopers-module--btnShowMore--37874";
export var card = "HireDevelopers-module--card--07c39";
export var cardDesc = "HireDevelopers-module--cardDesc--fc990";
export var cardWrapper = "HireDevelopers-module--cardWrapper--7a020";
export var description = "HireDevelopers-module--description--09e7f";
export var devContent = "HireDevelopers-module--devContent--b7551";
export var developerImg = "HireDevelopers-module--developerImg--37915";
export var developersData = "HireDevelopers-module--developersData--be25d";
export var experience = "HireDevelopers-module--experience--e0f80";
export var expert = "HireDevelopers-module--expert--dc329";
export var heading = "HireDevelopers-module--heading--f2cd5";
export var mernIcon = "HireDevelopers-module--mernIcon--644d1";
export var review = "HireDevelopers-module--review--1af3b";
export var skill = "HireDevelopers-module--skill--766c8";
export var skills = "HireDevelopers-module--skills--6b300";
export var typeBtn = "HireDevelopers-module--typeBtn--86664";
export var typedevBtn = "HireDevelopers-module--typedevBtn--b924b";
export var whitePlus = "HireDevelopers-module--whitePlus--fb1bf";