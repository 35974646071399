// extracted by mini-css-extract-plugin
export var bannerBtnHowAngular = "HowHireAngularMobile-module--bannerBtnHowAngular--718e6";
export var card = "HowHireAngularMobile-module--card--57602";
export var cardAllData = "HowHireAngularMobile-module--cardAllData--cdc5f";
export var cardContent = "HowHireAngularMobile-module--cardContent--bfff3";
export var cardWrapper = "HowHireAngularMobile-module--cardWrapper--2301e";
export var description = "HowHireAngularMobile-module--description--86312";
export var getBtnAngular = "HowHireAngularMobile-module--getBtnAngular--4c7f6";
export var headerContent = "HowHireAngularMobile-module--headerContent--7ea3c";
export var heading = "HowHireAngularMobile-module--heading--fc8be";
export var howHireAngular = "HowHireAngularMobile-module--howHireAngular--3690c";
export var lottieImage = "HowHireAngularMobile-module--lottieImage--4f0c5";
export var stepsDesktop = "HowHireAngularMobile-module--stepsDesktop--29d52";
export var stepsMobile = "HowHireAngularMobile-module--stepsMobile--147ff";