import React from "react"
import * as styles from "./HowHireAngularMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
const HowHireAngular = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.howHireAngular}>
        <Container>
          <Row
            className={`gap-30 justify-content-center ${styles.cardWrapper}`}
          >
            <Col lg={5}>
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
            <Col lg={7}>
              <Row>
                {strapiData?.cards?.map((e, i) => (
                  <Col lg={12} md={6} className={styles.card}>
                    <div className={`d-flex ${styles.cardAllData}`}>
                      <div className={styles.cardContent}>
                        <div className={`  d-flex ${styles.headerContent}`}>
                          <span className={styles.stepsMobile}>
                            Step 0{i + 1}
                          </span>
                          <h3>{e?.title}</h3>
                          <span className={styles.stepsDesktop}>
                            Step 0{i + 1}
                          </span>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                        <div className={styles.lottieImage}>
                          <lottie-player
                            autoplay
                            loop
                            src={e?.image1[0]?.localFile?.publicURL}
                            style={{
                              height: "100px",
                              width: "100px",
                              margin: "0",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <div className={`${styles.bannerBtnHowAngular}`}>
              <Link to="#hirewebform" className={styles.getBtnAngular}>
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HowHireAngular
